<template>
  <div id="intelligentConstructionDetail">
    <div class="cm-container">
      <breadcrumb></breadcrumb>
      <div class="line-c">
        <div class="ll-c">
          <img src="@/assets/img/courseManagement/cm-02.png" alt="">
        </div>
        <div class="lr-c">
          <div class="lr-title">地下工程-安全员全套课程</div>
          <div class="lrnl">课程发布时间：2021-12-30</div>
          <div class="lrnl">
            <span class = "tb">制作单位：湖南建筑高级技工学校</span>
            <span class="spline"></span>
            <span class = "tb">制作人：累心平</span>
            <span class="spline"></span>
            <span class = "tb">制作时间：2020-12-20</span>
            <span class="spline"></span>
            <span class = "tb">审核人：累心平</span>
          </div>
          <div class="lrnl nb">课程依据：混凝土预制构件制作细则;《建设工程施工现场消防安全技术规范》GB 50720-2011、《施工现场临时建筑物技术规范》 JGJ/T 188-2009</div>
          <div class="lrnl fb">
            <div class="lrnl-btn" @click="showb(1)" :class = "{active:bindex == 1}">实训学习</div>
            <div class="lrnl-btn" @click="showb(2)" :class = "{active:bindex == 2}">互动视频实训</div>
            <div class="lrnl-btn" @click="showb(3)" :class = "{active:bindex == 3}">孪生实景实训</div>
            <div class="lrnl-btn" @click="showb(4)" :class = "{active:bindex == 4}">互动游戏实训</div>
          </div>
        </div>
      </div>
      <div class="nt">实训课程介绍</div>
      <div class="ntc">本专业培养理想信念坚定、德技并修、全面发展，具有一定的科学文化水平、良好的职业道德和工匠精神、较强的就业创业能力，具有支撑终身发展、适应时代要求的关键能力，掌握建工程技术专业知识和技术技能，面向建筑工程施工领域，能够从事建筑工程施工与管理等工作的高素质技术技能人才。</div>
      <div class="nt">实训课程知识点</div>
      <div class="ntc">
        <div class="tl-c c01">
          <div class="tlol">沥青材料</div>
          <div class="tlol">石灰</div>
          <div class="tlol">水泥</div>
          <div class="tlol">骨料</div>
          <div class="tlol">混凝土</div>
        </div>
        <div class="tl-c c02">
          <div class="tlol">基础与地下室</div>
          <div class="tlol">墙体</div>
          <div class="tlol">楼地层</div>
          <div class="tlol">屋顶</div>
          <div class="tlol">楼梯</div>
        </div>     
      </div>
      <div class="nt">实训课程注意事项</div>
      <div class="ntc">未经作者授权，禁止转载</div>
    </div>
    <div class="a-box" v-if = "isboxShow && boxData">
      <div class="b-mask"></div>
      <div class="b-content-c">
        <div class="bcc-title">
          <div class="t-text">{{boxData.btitle}}</div>
          <div class="closeIcon" @click="hideb">
            &times;
          </div>
        </div>
        <div class="list-c" v-if = "boxData.dlist && boxData.dlist.length">
          <div class="l-oneline" v-for = "(item,index) in boxData.dlist" :key = "index">
            <div class="loltext">{{item.text}}</div>
            <div class="lolbtn" @click="goin">进入实训</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setStorage } from "@/js/common";
import breadcrumb from "@/components/breadcrumb";
// import router from "../../router/index.js";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      boxData:{
        btitle:'请选择实训章节',
        dlist:[
          {
            text:'6.软件基本界面与操作'
          },
                    {
            text:'7.梁柱截面尺寸估算及建模'
          },
                    {
            text:'8.楼板、楼梯建模'
          },
                    {
            text:'11.楼层组装'
          },
        ]
      },
      isboxShow:false,
      bindex:null,
      major: [
        { name: "全部", id: 1 },
        { name: "建筑工程", id: 2 },
        { name: "道桥工程", id: 3 },
        { name: "地下工程", id: 4 },
      ],
      majoractive: 1,
      jobs: [
        { name: "全部", id: 1 },
        { name: "施工员（测量员）", id: 2 },
        { name: "质量员", id: 3 },
        { name: "安全员", id: 4 },
        { name: "标准员", id: 5 },
        { name: "材料员", id: 6 },
        { name: "劳务员（预算员）", id: 7 },
        { name: "资料员", id: 8 },
      ],
      jobsactive: 1,
    });
    const setbclist = (arr)=>{
      store.commit("Setbclist", arr);
      setStorage("bclist", arr);
    }
    const methods = {
      goin:() =>{
        router.push("/home/practicalTraining");
      },
      showb:(index) =>{
        if(state.bindex != index){
          state.bindex = index;
        }
        if(index == 1){ // 实训学习
          let arr = [
            {
              text: "智慧施工",
              nindex: "5",
              path: "/home/intelligentConstruction",
              back:false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back:true
            },
            {
              text: "课程详情",
              nindex: "",
              path: "",
              back:false
            }
          ]
          setbclist(arr);
          router.push("/home/courseDetails");
        }else if(index ==2){ // 互动视频实训
          let arr = [
            {
              text: "智慧施工",
              nindex: "5",
              path: "/home/intelligentConstruction",
              back:false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back:true
            },
            {
              text: "实训",
              nindex: "",
              path: "",
              back:false
            }
          ]
          setbclist(arr);
          router.push("/home/task02");
        }else if(index ==3){ // 孪生实景实训
          let arr = [
            {
              text: "智慧施工",
              nindex: "5",
              path: "/home/intelligentConstruction",
              back:false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back:true
            },
            {
              text: "实训",
              nindex: "",
              path: "",
              back:false
            }
          ]
          setbclist(arr);
          router.push("/home/task01");
        }else if(index ==4){ // 互动游戏实训
          let arr = [
            {
              text: "智慧施工",
              nindex: "5",
              path: "/home/intelligentConstruction",
              back:false
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back:true
            },
            {
              text: "实训",
              nindex: "",
              path: "",
              back:false
            }
          ]
          setbclist(arr);
          router.push("/home/task03");
        }
      },
      hideb:() =>{
        state.isboxShow = false;
        state.bindex = null;
      },
      back: () => {
        store.commit("SetnarbarMenuActive", "5");
        setStorage("narbarMenuActive", "5");
        router.push("/home/intelligentConstruction");
      },
    };
    onMounted(() => {});
    onUnmounted(() => {});
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {breadcrumb},
};
</script>
<style lang="scss" scoped>
#intelligentConstructionDetail {
  height: 100%;
  position: relative;
  .cm-container {
    width: 1200px;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 0 0 100px;
    .line-c{
      background: #fff;
      padding:20px;
      display: flex;
      .ll-c{
        width:290px;
        height:192px;
        img{
          width:100%;
          height:100%;
        }
      }
      .lr-c{
        padding:0 0 0 20px;
        flex-grow:1;
        .lr-title{
          font-size: 18px;
          color: #333333;
          line-height: 24px;
          margin:0 0 2px;
        }
        .lrnl{
          font-size: 14px;
          color: #666666;
          line-height: 19px;
          padding:10px 0;
          border-bottom:1px solid #EEEEEE;
          &.nb{
            border:none;
          }
          &.fb{
            display: flex;
          }
          .lrnl-btn{
            height:34px;
            line-height: 34px;
            padding:0 20px;
            font-size: 14px;
            color:#059DF7;
            border: 1px solid #059DF7;
            box-sizing: border-box;
            margin:0 20px 0 0;
            cursor: pointer;
            &.active,&:hover{
              background: #059DF7;
              color:#fff;
            }

          }
          .spline{
            display: inline-block;
            width:1px;
            height:9.5px;
            margin:0 20px;
            background: #eee;
          }
          &:last-child{
            border:none;
          }
        }

      }
    }
    .nt{
      height: 20px;
      font-size: 15px;
      font-weight: bold;
      color: #555555;
      line-height: 20px;
      margin:20px 0 14px;
    }
    .ntc{
      font-size: 14px;
      color: #555555;
      line-height: 19px;
      background: #fff;
      padding:20px;
      .tl-c{
        padding:0 0 0 15px;
        &.c01{
          margin:0 0 20px;
        }
        .tlol{
          position:relative;
          &::before{
            content:'';
            width: 4px;
            height: 4px;
            background: #333333;
            border-radius: 50%;
            position:absolute;
            left: -10px;
            top: 9px;
          }
        }
      }
    }
    .cm-img{
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 -20px;
      .cm-img-list{
        width: 224px;
        height: 200px;
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        margin-right: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        img{
          width: 100%;
          height: 78%;
          display: inline-block;
        }
        div{
          height: 22%;
          margin: 4px 0 0 10px;
          box-sizing: border-box;
          font-size: 14px;
          color: #333333;
        }
        &:nth-child(5n){
          margin-right: 0;
        }
      }
    }
  }
  .a-box{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 99;
    .b-mask{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;

    }
    .b-content-c{
      position:absolute;
      top: 50%;
      left:50%;
      transform: translate(-50%,-50%);
      min-width:500px;
      background: #fff;
      box-shadow: 0px 0px 7px #00000057;
      .bcc-title{
        padding:0 20px;
        height:40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
        border-bottom:1px solid #EEEEEE;
        display: flex;
        justify-content:space-between;
        .closeIcon{
          font-size: 30px;
          cursor: pointer;
          color:#979797;
          font-weight: normal;
        }
      }
      .list-c{
        min-height:168px;
        padding:18px 20px;
        .l-oneline{
          display: flex;
          justify-content: space-between;
          margin:0 0 10px;
          align-items: center;
          .loltext{
            font-size: 14px;
            color: #333333;
            line-height: 20px;
          }
          .lolbtn{
            height:20px;
            line-height: 20px;
            padding:0 10px;
            color:#059DF7;
            border: 1px solid #059DF7;
            cursor: pointer;
            font-size: 10px;
            &:hover{
              color:#fff;
              background: #059DF7;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #intelligentConstructionDetail {
    .cm-container {
      width: 1400px;
      .cm-img{
        .cm-img-list{
        width: 264px;
        height: 235px;
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        margin-right: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        &:nth-child(5n){
          margin-right: 0;
        }
        }
      }
      .line-c{
        background: #fff;
        padding:20px;
        display: flex;
        .ll-c{
          width:338.33px;
          height:224px;
        }
      }
    }
  }
}
</style>
